export default function Footer() {
    return (

        <div className="flex justify-center w-full bg-[#4A344E] py-5" >
            <div className="font-second text-xs text-white">
                Made with <br />
                by Zuhdan Nur Ihsan & Design By Rafli Rafky
            </div>
        </div>
    )
}